import { Controller } from "@hotwired/stimulus"

const formatMoney = (cents) => {
  const money = (cents / 100).toFixed(2);
  const [integer, decimal] = money.split('.');
  const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `${formattedInteger},${decimal}`;
}

export default class MoneyInputController extends Controller {
  static targets = [
    'money',
  ]

  changed() {
    this.moneyTarget.value = formatMoney(parseInt('0' + this.moneyTarget.value.replace(/[^0-9]/g, '')));
  }
}
