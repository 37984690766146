// Import and register all your controllers from the importmap under controllers/*
import { application } from './application'

import AddressController from './address_controller';
import MaskController from './mask_controller';
import MoneyInputController from './money_input_controller';
import PercentInputController from './percent_input_controller';
import ProvinceCityController from './province_city_controller'
import ClientsFormController from './clients_form_controller';
import CityController from './city_controller';

application.register('province-city', ProvinceCityController);
application.register('address', AddressController);
application.register('money-input', MoneyInputController);
application.register('percent-input', PercentInputController);
application.register('mask', MaskController);
application.register('clients-form', ClientsFormController);
application.register('city', CityController);
