import { Controller } from "@hotwired/stimulus"

const formatPercent = (cents) => {
  const money = (cents / 100).toFixed(2);
  const [integer, decimal] = money.split('.');
  const formattedInteger = integer.replace(/\B(?=(\d{2})+(?!\d))/g, '');

  return `${formattedInteger},${decimal}`;
}

export default class PercentInputController extends Controller {
  static targets = [
    'percent',
  ]

  connect() {
    this.maxValue = this.percentTarget.dataset.maxValue || 100;
  }

  changed() {
    this.percentTarget.value = formatPercent(parseInt('0' + this.percentTarget.value.replace(/[^0-9]/g, '')));
    if (parseInt(this.percentTarget.value) > this.maxValue)
      this.percentTarget.value = this.currentValue;
    this.currentValue = this.percentTarget.value;
  }
}
