import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['province', 'city', 'street', 'neighborhood', 'zip']
  static outlets = ['address']

  copyAddress(event) {
    event.preventDefault();

    this.provinceTarget.value = this.addressOutlet.provinceTarget.value;
    this.cityTarget.innerHTML = this.addressOutlet.cityTarget.innerHTML;
    this.cityTarget.value = this.addressOutlet.cityTarget.value;
    this.streetTarget.value = this.addressOutlet.streetTarget.value;
    this.neighborhoodTarget.value = this.addressOutlet.neighborhoodTarget.value;
    this.zipTarget.value = this.addressOutlet.zipTarget.value;
  }
}
