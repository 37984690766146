import { Controller } from "@hotwired/stimulus"

export default class CityController extends Controller {
  static targets = [
    'sellerSelector',
    'citySelector'
  ];

  sellerChanged() {
    const sellerId = this.sellerSelectorTarget.value;
    if (!sellerId) return;

    const url = `/cities/by_seller/${sellerId}`;
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.citySelectorTarget.innerHTML = ([{id: '', name: ''}].concat(data)).map(city => {
          return `<option value="${city.id}">${city.name}</option>`;
        }).join('');
      })
  }
}
