import { Controller } from "@hotwired/stimulus"

export default class ProvinceCityController extends Controller {
  static targets = [
    'provinceSelector',
    'citySelector'
  ]
  static values = {
    cities: Object
  }

  refreshCities() {
    const cities_by_province = this.citiesValue[this.provinceSelectorTarget.value] || [];
    this.citySelectorTarget.innerHTML = cities_by_province.map(city => {
      return `<option value="${city.id}">${city.name}</option>`;
    }).join('');
  }
}
