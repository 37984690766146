import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = ['legalId', 'legalEntity', 'extraCnpjs', 'fantasy'];

  connect() {
    this.legalIdMask = IMask(this.legalIdTarget, this.legalIdPattern);
    this.legalEntityChanged();
  }

  get isLegalEntity() {
    return this.legalEntityTargets.find(element => element.checked).value === 'true';
  }

  get legalIdPattern() {
    return this.isLegalEntity ? '00.000.000/0000-00' : '000.000.000-00';
  }

  legalEntityChanged() {
    const isLegalEntity = this.isLegalEntity;

    this.legalIdMask.updateOptions({ mask: this.legalIdPattern });

    this.extraCnpjsTarget.style.display = isLegalEntity ? 'block' : 'none';
    this.extraCnpjsTarget.querySelectorAll("input").forEach(input => input.disabled = !isLegalEntity);

    this.fantasyTarget.required = isLegalEntity;
  }

  disconnect() {
    this.legalIdMask?.destroy();
  }
}
